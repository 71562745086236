/* tslint:disable */
/* eslint-disable */
/**
 * Ui Extension API
 * The Ui Extension Management API enables brX developers to manage content types through a set of endpoints
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import {UiExtension} from "../models/integrations/ui-extension";
/**
 * UiExtensionOperationsApi - axios parameter creator
 * @export
 */
export const UiExtensionOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a content type
         * @param {string} UiExtensionName Name of content type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUiExtension: async (UiExtensionName: string, options: any = {}): Promise<RequestArgs> => {

            // verify required parameter 'UiExtensionName' is not null or undefined
            if (UiExtensionName === null || UiExtensionName === undefined) {
                throw new RequiredError('UiExtensionName','Required parameter UiExtensionName was null or undefined when calling getUiExtension.');
            }
            const localVarPath = `/{ui_extension_name}`
                .replace(`{${"ui_extension_name"}}`, encodeURIComponent(String(UiExtensionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all content types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUiExtensions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update a content type
         * @param {string} UiExtensionName Name of content type
         * @param {UiExtension} [body]
         * @param {string} [xResourceVersion] Resource&#x27;s version. Must be used in any subsequent update requests of this resource. Can be obtained when GETting the resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUiExtension: async (UiExtensionName: string, body?: UiExtension, xResourceVersion?: string, options: any = {}): Promise<RequestArgs> => {

            // verify required parameter 'UiExtensionName' is not null or undefined
            if (UiExtensionName === null || UiExtensionName === undefined) {
                throw new RequiredError('UiExtensionName','Required parameter UiExtensionName was null or undefined when calling putUiExtension.');
            }
            const localVarPath = `/{ui_extension_name}`
                .replace(`{${"ui_extension_name"}}`, encodeURIComponent(String(UiExtensionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            if (xResourceVersion !== undefined && xResourceVersion !== null) {
                localVarHeaderParameter['X-Resource-Version'] = String(xResourceVersion);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UiExtensionOperationsApi - functional programming interface
 * @export
 */
export const UiExtensionOperationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a content type
         * @param {string} UiExtensionName Name of content type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUiExtension(UiExtensionName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiExtension>> {
            const localVarAxiosArgs = await UiExtensionOperationsApiAxiosParamCreator(configuration).getUiExtension(UiExtensionName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all content types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUiExtensions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UiExtension>>> {
            const localVarAxiosArgs = await UiExtensionOperationsApiAxiosParamCreator(configuration).getUiExtensions( options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create or update a content type
         * @param {string} UiExtensionName Name of content type
         * @param {UiExtension} [body]
         * @param {string} [xResourceVersion] Resource&#x27;s version. Must be used in any subsequent update requests of this resource. Can be obtained when GETting the resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUiExtension( UiExtensionName: string, body?: UiExtension, xResourceVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UiExtension>> {
            const localVarAxiosArgs = await UiExtensionOperationsApiAxiosParamCreator(configuration).putUiExtension( UiExtensionName, body, xResourceVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UiExtensionOperationsApi - factory interface
 * @export
 */
export const UiExtensionOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a content type
         * @param {string} UiExtensionName Name of content type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUiExtension( UiExtensionName: string, options?: any): AxiosPromise<UiExtension> {
            return UiExtensionOperationsApiFp(configuration).getUiExtension( UiExtensionName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all content types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUiExtensions(options?: any): AxiosPromise<Array<UiExtension>> {
            return UiExtensionOperationsApiFp(configuration).getUiExtensions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update a content type
         * @param {string} UiExtensionName Name of content type
         * @param {UiExtension} [body]
         * @param {string} [xResourceVersion] Resource&#x27;s version. Must be used in any subsequent update requests of this resource. Can be obtained when GETting the resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUiExtension( UiExtensionName: string, body?: UiExtension, xResourceVersion?: string, options?: any): AxiosPromise<UiExtension> {
            return UiExtensionOperationsApiFp(configuration).putUiExtension( UiExtensionName, body, xResourceVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UiExtensionOperationsApi - object-oriented interface
 * @export
 * @class UiExtensionOperationsApi
 * @extends {BaseAPI}
 */
export class UiExtensionOperationsApi extends BaseAPI {
    /**
     * 
     * @summary Get a content type
     * @param {string} UiExtensionName Name of content type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UiExtensionOperationsApi
     */
    public getUiExtension( UiExtensionName: string, options?: any) {
        return UiExtensionOperationsApiFp(this.configuration).getUiExtension( UiExtensionName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all content types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UiExtensionOperationsApi
     */
    public getUiExtensions( options?: any) {
        return UiExtensionOperationsApiFp(this.configuration).getUiExtensions(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create or update a content type
     * @param {string} UiExtensionName Name of content type
     * @param {UiExtension} [body]
     * @param {string} [xResourceVersion] Resource&#x27;s version. Must be used in any subsequent update requests of this resource. Can be obtained when GETting the resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UiExtensionOperationsApi
     */
    public putUiExtension( UiExtensionName: string, body?: UiExtension, xResourceVersion?: string, options?: any) {
        return UiExtensionOperationsApiFp(this.configuration).putUiExtension(UiExtensionName, body, xResourceVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
