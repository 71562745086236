import React from 'react';
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Button,
    Checkbox,
    Container,
    Dialog,
    Divider,
    FormControlLabel,
    FormGroup,
    Toolbar,
    Typography,
    withStyles
} from "@material-ui/core";
import {getBaseUrl, getRunTimeContentTypeOperationsApi, getRunTimeUiExtensionsApi} from "../ApiContext";
import {LogContext} from "../LogContext";
import {UiExtension} from "../api/models/integrations/ui-extension";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {JSONSchema7} from "json-schema";
import Form from "@rjsf/material-ui";
import {settingSchema} from "../settings/Settings";
import {Cookies} from "react-cookie";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import {ContentType, ContentTypeField} from "../api/models/contenttype";

const uiExtensionSnippet: ContentTypeField = {
    "name": "assets",
    "type": "OpenUiExtension",
    "required": false,
    "multiple": false,
    "presentation": {
        "caption": "Assets",
        "hint": "",
        "layoutColumn": 1,
        "extensionType": "brandfolderdam"
    },
    "validations": {},
    "defaultValue": [
        ""
    ]
}

// @ts-ignore
const styles = theme => {
    return ({
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '80%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    });
};

type UiState = {
    items: Array<UiExtension>
    namespace: string | undefined
    apiKey: string | undefined
    configForm: any
    uiExtensionApiEnabled: boolean
    uiExtension: UiExtension | undefined
    uiExtensionInstalled: boolean
    contentTypeApiEnabled: boolean
    contentTypes: Array<ContentType>
}
type UiProps = {
    classes: any
}

const cookies = new Cookies();


class UiExtensionsFrame extends React.Component<UiProps, UiState> {

    static contextType = LogContext;
    context!: React.ContextType<typeof LogContext>;

    constructor(props: UiProps) {
        super(props);

        const queryParams = new URLSearchParams(window.location.search)
        let configForm = {}
        let uiExtension = undefined
        try {
            uiExtension = JSON.parse(queryParams.get("uiExtension") as string);
            configForm = JSON.parse(queryParams.get("configForm") as string);
        } catch (e: any) {

        }

        this.state = {
            items: [],
            namespace: cookies.get("namespace"),
            apiKey: cookies.get('apiKey'),
            configForm: configForm,
            uiExtensionApiEnabled: false,
            uiExtension: uiExtension,
            uiExtensionInstalled: false,
            contentTypeApiEnabled: false,
            contentTypes: []
        }
    }

    componentDidMount(): void {
        const {namespace, apiKey, uiExtension} = this.state;


        {
            (namespace && apiKey) && getRunTimeContentTypeOperationsApi(namespace, apiKey).getContentTypes('development')
                .then(response => this.setState({
                    contentTypes: response?.data ?? [],
                    contentTypeApiEnabled: true,
                }))
                .catch(reason => this.setState({contentTypeApiEnabled: false}))
        }

        {
            (namespace && apiKey) && getRunTimeUiExtensionsApi(namespace, apiKey).getUiExtensions()
                .then(response => this.setState({
                    items: response?.data ?? [],
                    uiExtensionApiEnabled: true,
                    uiExtensionInstalled: response.data.some(item => item.id === uiExtension?.id)
                }))
                .catch(reason => this.setState({uiExtensionApiEnabled: false}))

        }


    }

    save({apiKey, namespace}: { apiKey: string | undefined; namespace: string | undefined }): void {
        this.setState({namespace: namespace, apiKey: apiKey}, () => {
            cookies.set('namespace', namespace, {secure: true, sameSite: 'none'});
            cookies.set('apiKey', apiKey, {secure: true, sameSite: 'none'});
            window.location.reload()
        })
    }

    render() {
        const {
            namespace,
            apiKey,
            configForm,
            uiExtensionApiEnabled,
            uiExtension,
            uiExtensionInstalled,
            contentTypeApiEnabled,
            contentTypes
        } = this.state;
        let settingsModel = {namespace: namespace, apiKey: apiKey};
        let pluginConfig = {};
        const settingsDefaultExpanded = (namespace && apiKey) ? false : true
        const {classes} = this.props;
        return (
            <Dialog open={true} fullScreen id={'installDialog'}>
                <Accordion defaultExpanded={settingsDefaultExpanded}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}>
                        <Typography className={classes.heading}>API Connection Settings</Typography>
                        <Typography className={classes.secondaryHeading}>{uiExtensionApiEnabled ?
                            <CheckCircleOutlineOutlinedIcon color={"primary"}/> :
                            <ErrorOutlinedIcon color={"error"}/>}</Typography>
                    </AccordionSummary>
                    <Container>
                        <Form
                            autoComplete={"off"}
                            formData={settingsModel}
                            schema={settingSchema as JSONSchema7}
                            onChange={({formData}) => (settingsModel = formData)}>
                            <></>
                        </Form>
                    </Container>
                    <AccordionActions>
                        <Button
                            // disabled={!isNotEmptyOrNull(this.state.channels)}
                            size={"small"}
                            variant="outlined"
                            color="primary"
                            style={{marginRight: '10px'}}
                            startIcon={<SaveOutlinedIcon/>}
                            onClick={() => this.save(settingsModel)}
                        >
                            Save Connection Settings
                        </Button>
                    </AccordionActions>
                </Accordion>
                {Object.keys(configForm).length > 0 &&
                    <Accordion expanded={(uiExtensionApiEnabled && !uiExtensionInstalled) ? true : false}>
                        <AccordionSummary expandIcon={<ChevronRightOutlinedIcon/>}>
                            <Typography className={classes.heading}>Plugin Configuration</Typography>
                            <Typography className={classes.secondaryHeading}>{uiExtensionInstalled &&
                                <CheckCircleOutlineOutlinedIcon color={"primary"}
                                                                onClick={() => window.open(`${getBaseUrl()}/cms/integrations/ui-extensions/${uiExtension?.id}`, 'new')}/>}</Typography>
                        </AccordionSummary>
                        <Container>
                            <Form
                                autoComplete={"off"}
                                liveValidate={true}
                                schema={configForm as JSONSchema7}
                                onChange={({formData}) => {
                                    pluginConfig = formData
                                }}
                            >
                                <></>
                            </Form>
                        </Container>
                    </Accordion>}
                {(uiExtensionInstalled && contentTypeApiEnabled) &&
                    <Accordion expanded>
                        <AccordionSummary>
                            <Typography>Content Types</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant={"body2"}>Add UI Extension to Content Type(s):</Typography>
                            <Divider/>
                            <div>
                                {(contentTypes && contentTypes.length > 0) &&
                                    <FormGroup>
                                        {contentTypes.filter(type => type.enabled).map(type => {
                                            const uiExtensionField = {
                                                ...uiExtensionSnippet,
                                                name: uiExtension?.id,
                                                presentation: {
                                                    ...uiExtensionSnippet.presentation,
                                                    caption: uiExtension?.displayName,
                                                    extensionType: uiExtension?.id
                                                }
                                            } as ContentTypeField
                                            const checked = type.fields?.some((field) => {
                                                return (field.type === uiExtensionField.type && field?.presentation?.extensionType === uiExtensionField.presentation.extensionType)
                                            })
                                            return (
                                                <FormControlLabel key={type.name}
                                                                  control={
                                                                      <Checkbox
                                                                          defaultChecked={checked}
                                                                          onChange={(event) => {
                                                                              const contentTypeApi = (namespace && apiKey) && getRunTimeContentTypeOperationsApi(namespace, apiKey);
                                                                              contentTypeApi && contentTypeApi
                                                                                  .getContentType('development', type.name)
                                                                                  .then(response => {
                                                                                      const version = response.headers['x-resource-version'];
                                                                                      const ctype: ContentType = response.data as ContentType;
                                                                                      const isAdd = event.target.checked;

                                                                                      if (isAdd) {
                                                                                          ctype.fields?.push(uiExtensionField);
                                                                                      } else {
                                                                                          ctype.fields = ctype.fields?.filter((field) => {
                                                                                              return !(field.type === uiExtensionField.type && field?.presentation?.extensionType === uiExtensionField.presentation.extensionType)
                                                                                          })
                                                                                      }
                                                                                      contentTypeApi.putContentType("development", ctype.name, ctype, version)
                                                                                  })
                                                                          }}
                                                                          color="primary"
                                                                      />
                                                                  }
                                                                  label={type.presentation.displayName ?? type.name}
                                                />
                                            )
                                        })}
                                    </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>}
                <div style={{marginBottom: '30px'}}></div>
                <AppBar variant={"outlined"} color={"default"} style={{bottom: 0, top: "auto"}}>
                    <Toolbar>
                        <Button
                            disabled={(uiExtensionApiEnabled && !uiExtensionInstalled) ? false : true}
                            style={{marginRight: 10}}
                            variant="outlined"
                            color="primary"
                            size={"medium"}
                            startIcon={<AddOutlinedIcon/>}
                            onClick={() => {
                                (namespace && apiKey && uiExtension?.id) && getRunTimeUiExtensionsApi(namespace, apiKey)
                                    .putUiExtension(uiExtension.id, {
                                        ...uiExtension,
                                        config: JSON.stringify(pluginConfig)
                                    })
                                    .then(response => response.status === 201 && this.setState({uiExtensionInstalled: true}))
                                    .then(() => this.componentDidMount())
                            }}
                        >
                            Install {uiExtension?.displayName}
                        </Button>
                    </Toolbar>
                </AppBar>
            </Dialog>)
    }

}

export default withStyles(styles)(UiExtensionsFrame);
