/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CatalogGroup } from '../models/site';
import { ComponentDefinition } from '../models/site';
/**
 * ChannelCatalogOperationsApi - axios parameter creator
 * @export
 */
export const ChannelCatalogOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete an empty channel catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelCatalogGroup: async (channelId: string, groupName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling deleteChannelCatalogGroup.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling deleteChannelCatalogGroup.');
            }
            const localVarPath = `/channels/{channel_id}/component_groups/{group_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"group_name"}}`, encodeURIComponent(String(groupName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an unreferenced channel component within a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {string} componentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelCatalogGroupComponent: async (channelId: string, groupName: string, componentName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling deleteChannelCatalogGroupComponent.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling deleteChannelCatalogGroupComponent.');
            }
            // verify required parameter 'componentName' is not null or undefined
            if (componentName === null || componentName === undefined) {
                throw new RequiredError('componentName','Required parameter componentName was null or undefined when calling deleteChannelCatalogGroupComponent.');
            }
            const localVarPath = `/channels/{channel_id}/component_groups/{group_name}/components/{component_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"group_name"}}`, encodeURIComponent(String(groupName)))
                .replace(`{${"component_name"}}`, encodeURIComponent(String(componentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a channel catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelCatalogGroup: async (channelId: string, groupName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannelCatalogGroup.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getChannelCatalogGroup.');
            }
            const localVarPath = `/channels/{channel_id}/component_groups/{group_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"group_name"}}`, encodeURIComponent(String(groupName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a channel component within a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {string} componentName 
         * @param {boolean} [inherited] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelCatalogGroupComponent: async (channelId: string, groupName: string, componentName: string, inherited?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannelCatalogGroupComponent.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getChannelCatalogGroupComponent.');
            }
            // verify required parameter 'componentName' is not null or undefined
            if (componentName === null || componentName === undefined) {
                throw new RequiredError('componentName','Required parameter componentName was null or undefined when calling getChannelCatalogGroupComponent.');
            }
            const localVarPath = `/channels/{channel_id}/component_groups/{group_name}/components/{component_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"group_name"}}`, encodeURIComponent(String(groupName)))
                .replace(`{${"component_name"}}`, encodeURIComponent(String(componentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            if (inherited !== undefined) {
                localVarQueryParameter['inherited'] = inherited;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the channel components for a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelCatalogGroupComponents: async (channelId: string, groupName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannelCatalogGroupComponents.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling getChannelCatalogGroupComponents.');
            }
            const localVarPath = `/channels/{channel_id}/component_groups/{group_name}/components`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"group_name"}}`, encodeURIComponent(String(groupName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the channel catalog groups
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelCatalogGroups: async (channelId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannelCatalogGroups.');
            }
            const localVarPath = `/channels/{channel_id}/component_groups`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update a channel catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {CatalogGroup} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChannelCatalogGroup: async (channelId: string, groupName: string, body?: CatalogGroup, xResourceVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling putChannelCatalogGroup.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling putChannelCatalogGroup.');
            }
            const localVarPath = `/channels/{channel_id}/component_groups/{group_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"group_name"}}`, encodeURIComponent(String(groupName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            if (xResourceVersion !== undefined && xResourceVersion !== null) {
                localVarHeaderParameter['X-Resource-Version'] = String(xResourceVersion);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update a channel component within a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {string} componentName 
         * @param {ComponentDefinition} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChannelCatalogGroupComponent: async (channelId: string, groupName: string, componentName: string, body?: ComponentDefinition, xResourceVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling putChannelCatalogGroupComponent.');
            }
            // verify required parameter 'groupName' is not null or undefined
            if (groupName === null || groupName === undefined) {
                throw new RequiredError('groupName','Required parameter groupName was null or undefined when calling putChannelCatalogGroupComponent.');
            }
            // verify required parameter 'componentName' is not null or undefined
            if (componentName === null || componentName === undefined) {
                throw new RequiredError('componentName','Required parameter componentName was null or undefined when calling putChannelCatalogGroupComponent.');
            }
            const localVarPath = `/channels/{channel_id}/component_groups/{group_name}/components/{component_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"group_name"}}`, encodeURIComponent(String(groupName)))
                .replace(`{${"component_name"}}`, encodeURIComponent(String(componentName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            if (xResourceVersion !== undefined && xResourceVersion !== null) {
                localVarHeaderParameter['X-Resource-Version'] = String(xResourceVersion);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChannelCatalogOperationsApi - functional programming interface
 * @export
 */
export const ChannelCatalogOperationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete an empty channel catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChannelCatalogGroup(channelId: string, groupName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ChannelCatalogOperationsApiAxiosParamCreator(configuration).deleteChannelCatalogGroup(channelId, groupName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete an unreferenced channel component within a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {string} componentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChannelCatalogGroupComponent(channelId: string, groupName: string, componentName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ChannelCatalogOperationsApiAxiosParamCreator(configuration).deleteChannelCatalogGroupComponent(channelId, groupName, componentName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a channel catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelCatalogGroup(channelId: string, groupName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogGroup>> {
            const localVarAxiosArgs = await ChannelCatalogOperationsApiAxiosParamCreator(configuration).getChannelCatalogGroup(channelId, groupName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a channel component within a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {string} componentName 
         * @param {boolean} [inherited] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelCatalogGroupComponent(channelId: string, groupName: string, componentName: string, inherited?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComponentDefinition>> {
            const localVarAxiosArgs = await ChannelCatalogOperationsApiAxiosParamCreator(configuration).getChannelCatalogGroupComponent(channelId, groupName, componentName, inherited, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the channel components for a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelCatalogGroupComponents(channelId: string, groupName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComponentDefinition>>> {
            const localVarAxiosArgs = await ChannelCatalogOperationsApiAxiosParamCreator(configuration).getChannelCatalogGroupComponents(channelId, groupName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the channel catalog groups
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelCatalogGroups(channelId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CatalogGroup>>> {
            const localVarAxiosArgs = await ChannelCatalogOperationsApiAxiosParamCreator(configuration).getChannelCatalogGroups(channelId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create or update a channel catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {CatalogGroup} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putChannelCatalogGroup(channelId: string, groupName: string, body?: CatalogGroup, xResourceVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogGroup>> {
            const localVarAxiosArgs = await ChannelCatalogOperationsApiAxiosParamCreator(configuration).putChannelCatalogGroup(channelId, groupName, body, xResourceVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create or update a channel component within a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {string} componentName 
         * @param {ComponentDefinition} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putChannelCatalogGroupComponent(channelId: string, groupName: string, componentName: string, body?: ComponentDefinition, xResourceVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComponentDefinition>> {
            const localVarAxiosArgs = await ChannelCatalogOperationsApiAxiosParamCreator(configuration).putChannelCatalogGroupComponent(channelId, groupName, componentName, body, xResourceVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ChannelCatalogOperationsApi - factory interface
 * @export
 */
export const ChannelCatalogOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete an empty channel catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelCatalogGroup(channelId: string, groupName: string, options?: any): AxiosPromise<void> {
            return ChannelCatalogOperationsApiFp(configuration).deleteChannelCatalogGroup(channelId, groupName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an unreferenced channel component within a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {string} componentName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelCatalogGroupComponent(channelId: string, groupName: string, componentName: string, options?: any): AxiosPromise<void> {
            return ChannelCatalogOperationsApiFp(configuration).deleteChannelCatalogGroupComponent(channelId, groupName, componentName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a channel catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelCatalogGroup(channelId: string, groupName: string, options?: any): AxiosPromise<CatalogGroup> {
            return ChannelCatalogOperationsApiFp(configuration).getChannelCatalogGroup(channelId, groupName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a channel component within a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {string} componentName 
         * @param {boolean} [inherited] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelCatalogGroupComponent(channelId: string, groupName: string, componentName: string, inherited?: boolean, options?: any): AxiosPromise<ComponentDefinition> {
            return ChannelCatalogOperationsApiFp(configuration).getChannelCatalogGroupComponent(channelId, groupName, componentName, inherited, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the channel components for a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelCatalogGroupComponents(channelId: string, groupName: string, options?: any): AxiosPromise<Array<ComponentDefinition>> {
            return ChannelCatalogOperationsApiFp(configuration).getChannelCatalogGroupComponents(channelId, groupName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the channel catalog groups
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelCatalogGroups(channelId: string, options?: any): AxiosPromise<Array<CatalogGroup>> {
            return ChannelCatalogOperationsApiFp(configuration).getChannelCatalogGroups(channelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update a channel catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {CatalogGroup} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChannelCatalogGroup(channelId: string, groupName: string, body?: CatalogGroup, xResourceVersion?: string, options?: any): AxiosPromise<CatalogGroup> {
            return ChannelCatalogOperationsApiFp(configuration).putChannelCatalogGroup(channelId, groupName, body, xResourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update a channel component within a catalog group
         * @param {string} channelId 
         * @param {string} groupName 
         * @param {string} componentName 
         * @param {ComponentDefinition} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChannelCatalogGroupComponent(channelId: string, groupName: string, componentName: string, body?: ComponentDefinition, xResourceVersion?: string, options?: any): AxiosPromise<ComponentDefinition> {
            return ChannelCatalogOperationsApiFp(configuration).putChannelCatalogGroupComponent(channelId, groupName, componentName, body, xResourceVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChannelCatalogOperationsApi - object-oriented interface
 * @export
 * @class ChannelCatalogOperationsApi
 * @extends {BaseAPI}
 */
export class ChannelCatalogOperationsApi extends BaseAPI {
    /**
     * 
     * @summary Delete an empty channel catalog group
     * @param {string} channelId 
     * @param {string} groupName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelCatalogOperationsApi
     */
    public deleteChannelCatalogGroup(channelId: string, groupName: string, options?: any) {
        return ChannelCatalogOperationsApiFp(this.configuration).deleteChannelCatalogGroup(channelId, groupName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete an unreferenced channel component within a catalog group
     * @param {string} channelId 
     * @param {string} groupName 
     * @param {string} componentName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelCatalogOperationsApi
     */
    public deleteChannelCatalogGroupComponent(channelId: string, groupName: string, componentName: string, options?: any) {
        return ChannelCatalogOperationsApiFp(this.configuration).deleteChannelCatalogGroupComponent(channelId, groupName, componentName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a channel catalog group
     * @param {string} channelId 
     * @param {string} groupName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelCatalogOperationsApi
     */
    public getChannelCatalogGroup(channelId: string, groupName: string, options?: any) {
        return ChannelCatalogOperationsApiFp(this.configuration).getChannelCatalogGroup(channelId, groupName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a channel component within a catalog group
     * @param {string} channelId 
     * @param {string} groupName 
     * @param {string} componentName 
     * @param {boolean} [inherited] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelCatalogOperationsApi
     */
    public getChannelCatalogGroupComponent(channelId: string, groupName: string, componentName: string, inherited?: boolean, options?: any) {
        return ChannelCatalogOperationsApiFp(this.configuration).getChannelCatalogGroupComponent(channelId, groupName, componentName, inherited, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the channel components for a catalog group
     * @param {string} channelId 
     * @param {string} groupName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelCatalogOperationsApi
     */
    public getChannelCatalogGroupComponents(channelId: string, groupName: string, options?: any) {
        return ChannelCatalogOperationsApiFp(this.configuration).getChannelCatalogGroupComponents(channelId, groupName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the channel catalog groups
     * @param {string} channelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelCatalogOperationsApi
     */
    public getChannelCatalogGroups(channelId: string, options?: any) {
        return ChannelCatalogOperationsApiFp(this.configuration).getChannelCatalogGroups(channelId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create or update a channel catalog group
     * @param {string} channelId 
     * @param {string} groupName 
     * @param {CatalogGroup} [body] 
     * @param {string} [xResourceVersion] Resource&#x27;s version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelCatalogOperationsApi
     */
    public putChannelCatalogGroup(channelId: string, groupName: string, body?: CatalogGroup, xResourceVersion?: string, options?: any) {
        return ChannelCatalogOperationsApiFp(this.configuration).putChannelCatalogGroup(channelId, groupName, body, xResourceVersion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create or update a channel component within a catalog group
     * @param {string} channelId 
     * @param {string} groupName 
     * @param {string} componentName 
     * @param {ComponentDefinition} [body] 
     * @param {string} [xResourceVersion] Resource&#x27;s version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelCatalogOperationsApi
     */
    public putChannelCatalogGroupComponent(channelId: string, groupName: string, componentName: string, body?: ComponentDefinition, xResourceVersion?: string, options?: any) {
        return ChannelCatalogOperationsApiFp(this.configuration).putChannelCatalogGroupComponent(channelId, groupName, componentName, body, xResourceVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
