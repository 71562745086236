import {LogContext, LogContextType} from "../LogContext";
import {InstallationAction} from "../plugins/Plugins";
import {getGenericContentTypesApi, getGenericSiteApi} from "../ApiContext";
import React from "react";
import {GenericPluginApi} from "../api/apis/generic-plugin-api";

export function getId() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 9);
}

export function isNotEmptyOrNull(array: any) {
    return (typeof array !== 'undefined' && array.length > 0);
}

export function logError(message: string, context?: LogContextType | Partial<LogContextType>) {
    context && context.logError && context.logError(message);
}

export function logSuccess(message: string, context?: LogContextType | Partial<LogContextType>) {
    context && context.logSuccess && context.logSuccess(message);
}

export function setEndpoint(endpoint: string, context?: LogContextType | Partial<LogContextType>) {
    context && context.setEndpoint && context.setEndpoint(endpoint);
}

export const getNodeKey = ({node}: any) => node.id;

export function importActions(installationActions: Array<InstallationAction>, channelId: string | undefined, update: () => void, context: React.ContextType<typeof LogContext> | undefined) {
    const isArray = Array.isArray(installationActions);
    if (isArray) {
        (async function () {
            const genericSiteApi: GenericPluginApi = getGenericSiteApi();
            const genericContentTypesApi: GenericPluginApi = getGenericContentTypesApi();
            let success = true;
            let errors = []
            for (const installationAction of installationActions) {
                let genericPluginAPI: GenericPluginApi = installationAction.type === 'site' ? genericSiteApi : genericContentTypesApi
                console.log('update', installationAction.update)
                if (installationAction.update) {
                    try {
                        const result = await genericPluginAPI.get(installationAction.path, channelId);
                        if (result.status === 200) {
                            const version = result.headers['x-resource-version'];
                            await genericPluginAPI.put(installationAction.path, channelId, installationAction.body, version)
                        }
                    } catch (error: any) {
                        if (error.response.status === 404) {
                            const result = await genericPluginAPI.put(installationAction.path, channelId, installationAction.body)
                            if (result.status !== installationAction.responseCode) {
                                success = false;
                                errors.push(`${result.data?.reason} ${installationAction.path}`)
                            }
                        }
                    }
                } else {
                    try {
                        let result;
                        switch (installationAction.method){
                            case "PUT":
                                result = await genericPluginAPI.put(installationAction.path, channelId, installationAction.body)
                                break
                            case 'DELETE':
                                result = await genericPluginAPI.delete(installationAction.path, channelId)
                                break
                        }
                        if (Array.isArray(installationAction.responseCode) ? !installationAction.responseCode.includes(result.status) : result.status !== installationAction.responseCode) {
                            success = false;
                            errors.push(`${result.data?.reason} ${installationAction.path}`)
                        }
                    } catch (error: any) {
                        const status = error.response.status;
                        if (Array.isArray(installationAction.responseCode) ? !installationAction.responseCode.includes(status) : status !== installationAction.responseCode) {
                            success = false;
                            errors.push(`${error.response.data?.reason} ${installationAction.path}`)
                        }
                    }
                }
            }
            if (!success) {
                throw new Error(errors.join("\n"));
            }
        })().then(() => {
            update()
        }).catch(reason => {
            if(context){
                logError(reason.toString(), context);
            }
        })
    }
}

export function importFromClipBoard(channelId: string | undefined, update: () => void, context: React.ContextType<typeof LogContext>) {
    navigator.clipboard.readText().then(value => {
        const installationActions: Array<InstallationAction> = JSON.parse(value);
        importActions(installationActions, channelId, update, context)
    });
}

export function exportActions(installationActions: Array<InstallationAction>) {
    navigator.clipboard.writeText(JSON.stringify(installationActions))
    const event = new CustomEvent('record', {detail: installationActions});
    document.dispatchEvent(event);
}
