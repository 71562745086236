/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Page } from '../models/site';
/**
 * ChannelPageOperationsApi - axios parameter creator
 * @export
 */
export const ChannelPageOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Delete a channel page
         * @param {string} channelId
         * @param {string} pageName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelPage: async (channelId: string, layoutName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling deleteChannelPage.');
            }
            // verify required parameter 'layoutName' is not null or undefined
            if (layoutName === null || layoutName === undefined) {
                throw new RequiredError('layoutName','Required parameter layoutName was null or undefined when calling deleteChannelPage.');
            }
            const localVarPath = `/channels/{channel_id}/layouts/{layout_name}`
              .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"layout_name"}}`, encodeURIComponent(String(layoutName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a channel layout
         * @param {string} channelId
         * @param {string} layoutName
         * @param {boolean} [resolved]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelPage: async (channelId: string, layoutName: string, resolved?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannelPage.');
            }
            // verify required parameter 'layoutName' is not null or undefined
            if (layoutName === null || layoutName === undefined) {
                throw new RequiredError('layoutName','Required parameter layoutName was null or undefined when calling getChannelPage.');
            }
            const localVarPath = `/channels/{channel_id}/layouts/{layout_name}`
              .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"layout_name"}}`, encodeURIComponent(String(layoutName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            if (resolved !== undefined) {
                localVarQueryParameter['resolved'] = resolved;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the channel layouts
         * @param {string} channelId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelPages: async (channelId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannelPages.');
            }
            const localVarPath = `/channels/{channel_id}/layouts`
              .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create or update a channel layout
         * @param {string} channelId
         * @param {string} layoutName
         * @param {Layout} [body]
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChannelPage: async (channelId: string, pageName: string, body?: Page, xResourceVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling putChannelPage.');
            }
            // verify required parameter 'layoutName' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('layoutName','Required parameter layoutName was null or undefined when calling putChannelPage.');
            }
            const localVarPath = `/channels/{channel_id}/layouts/{layout_name}`
              .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"layout_name"}}`, encodeURIComponent(String(pageName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            if (xResourceVersion !== undefined && xResourceVersion !== null) {
                localVarHeaderParameter['X-Resource-Version'] = String(xResourceVersion);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChannelPageOperationsApi - functional programming interface
 * @export
 */
export const ChannelPageOperationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Delete a channel page
         * @param {string} channelId
         * @param {string} pageName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChannelPage(channelId: string, pageName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ChannelPageOperationsApiAxiosParamCreator(configuration).deleteChannelPage(channelId, pageName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get a channel page
         * @param {string} channelId
         * @param {string} pageName
         * @param {boolean} [resolved]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelPage(channelId: string, pageName: string, resolved?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await ChannelPageOperationsApiAxiosParamCreator(configuration).getChannelPage(channelId, pageName, resolved, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get the channel pages
         * @param {string} channelId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelPages(channelId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Page>>> {
            const localVarAxiosArgs = await ChannelPageOperationsApiAxiosParamCreator(configuration).getChannelPages(channelId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create or update a channel page
         * @param {string} channelId
         * @param {string} pageName
         * @param {Page} [body]
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putChannelPage(channelId: string, pageName: string, body?: Page, xResourceVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await ChannelPageOperationsApiAxiosParamCreator(configuration).putChannelPage(channelId, pageName, body, xResourceVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ChannelPageOperationsApi - factory interface
 * @export
 */
export const ChannelPageOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Delete a channel page
         * @param {string} channelId
         * @param {string} pageName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelPage(channelId: string, pageName: string, options?: any): AxiosPromise<void> {
            return ChannelPageOperationsApiFp(configuration).deleteChannelPage(channelId, pageName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a channel page
         * @param {string} channelId
         * @param {string} pageName
         * @param {boolean} [resolved]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelPage(channelId: string, pageName: string, resolved?: boolean, options?: any): AxiosPromise<Page> {
            return ChannelPageOperationsApiFp(configuration).getChannelPage(channelId, pageName, resolved, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get the channel pages
         * @param {string} channelId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelPages(channelId: string, options?: any): AxiosPromise<Array<Page>> {
            return ChannelPageOperationsApiFp(configuration).getChannelPages(channelId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create or update a channel page
         * @param {string} channelId
         * @param {string} pageName
         * @param {Page} [body]
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChannelPage(channelId: string, pageName: string, body?: Page, xResourceVersion?: string, options?: any): AxiosPromise<Page> {
            return ChannelPageOperationsApiFp(configuration).putChannelPage(channelId, pageName, body, xResourceVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChannelPageOperationsApi - object-oriented interface
 * @export
 * @class ChannelPageOperationsApi
 * @extends {BaseAPI}
 */
export class ChannelPageOperationsApi extends BaseAPI {
    /**
     *
     * @summary Delete a channel page
     * @param {string} channelId
     * @param {string} pageName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelPageOperationsApi
     */
    public deleteChannelPage(channelId: string, pageName: string, options?: any) {
        return ChannelPageOperationsApiFp(this.configuration).deleteChannelPage(channelId, pageName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get a channel page
     * @param {string} channelId
     * @param {string} pageName
     * @param {boolean} [resolved]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelPageOperationsApi
     */
    public getChannelPage(channelId: string, pageName: string, resolved?: boolean, options?: any) {
        return ChannelPageOperationsApiFp(this.configuration).getChannelPage(channelId, pageName, resolved, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get the channel pages
     * @param {string} channelId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelPageOperationsApi
     */
    public getChannelPages(channelId: string, options?: any) {
        return ChannelPageOperationsApiFp(this.configuration).getChannelPages(channelId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create or update a channel page
     * @param {string} channelId
     * @param {string} pageName
     * @param {Page} [body]
     * @param {string} [xResourceVersion] Resource&#x27;s version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelPageOperationsApi
     */
    public putChannelPage(channelId: string, pageName: string, body?: Page, xResourceVersion?: string, options?: any) {
        return ChannelPageOperationsApiFp(this.configuration).putChannelPage(channelId, pageName, body, xResourceVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
