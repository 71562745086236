/* tslint:disable */
/* eslint-disable */
/**
 * Content Type Management API
 * The Content Type Management API enables brX developers to manage content types through a set of endpoints
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ContentType } from '../models/contenttype';
/**
 * ContentTypeOperationsApi - axios parameter creator
 * @export
 */
export const ContentTypeOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a content type
         * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
         * @param {string} contentTypeName Name of content type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentType: async (projectId: string, contentTypeName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getContentType.');
            }
            // verify required parameter 'contentTypeName' is not null or undefined
            if (contentTypeName === null || contentTypeName === undefined) {
                throw new RequiredError('contentTypeName','Required parameter contentTypeName was null or undefined when calling getContentType.');
            }
            const localVarPath = `/{project_id}/{content_type_name}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"content_type_name"}}`, encodeURIComponent(String(contentTypeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all content types
         * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentTypes: async (projectId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getContentTypes.');
            }
            const localVarPath = `/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update a content type
         * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
         * @param {string} contentTypeName Name of content type
         * @param {ContentType} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version. Must be used in any subsequent update requests of this resource. Can be obtained when GETting the resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContentType: async (projectId: string, contentTypeName: string, body?: ContentType, xResourceVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling putContentType.');
            }
            // verify required parameter 'contentTypeName' is not null or undefined
            if (contentTypeName === null || contentTypeName === undefined) {
                throw new RequiredError('contentTypeName','Required parameter contentTypeName was null or undefined when calling putContentType.');
            }
            const localVarPath = `/{project_id}/{content_type_name}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"content_type_name"}}`, encodeURIComponent(String(contentTypeName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            if (xResourceVersion !== undefined && xResourceVersion !== null) {
                localVarHeaderParameter['X-Resource-Version'] = String(xResourceVersion);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentTypeOperationsApi - functional programming interface
 * @export
 */
export const ContentTypeOperationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a content type
         * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
         * @param {string} contentTypeName Name of content type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentType(projectId: string, contentTypeName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentType>> {
            const localVarAxiosArgs = await ContentTypeOperationsApiAxiosParamCreator(configuration).getContentType(projectId, contentTypeName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all content types
         * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentTypes(projectId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentType>>> {
            const localVarAxiosArgs = await ContentTypeOperationsApiAxiosParamCreator(configuration).getContentTypes(projectId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create or update a content type
         * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
         * @param {string} contentTypeName Name of content type
         * @param {ContentType} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version. Must be used in any subsequent update requests of this resource. Can be obtained when GETting the resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContentType(projectId: string, contentTypeName: string, body?: ContentType, xResourceVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentType>> {
            const localVarAxiosArgs = await ContentTypeOperationsApiAxiosParamCreator(configuration).putContentType(projectId, contentTypeName, body, xResourceVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentTypeOperationsApi - factory interface
 * @export
 */
export const ContentTypeOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a content type
         * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
         * @param {string} contentTypeName Name of content type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentType(projectId: string, contentTypeName: string, options?: any): AxiosPromise<ContentType> {
            return ContentTypeOperationsApiFp(configuration).getContentType(projectId, contentTypeName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all content types
         * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentTypes(projectId: string, options?: any): AxiosPromise<Array<ContentType>> {
            return ContentTypeOperationsApiFp(configuration).getContentTypes(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update a content type
         * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
         * @param {string} contentTypeName Name of content type
         * @param {ContentType} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version. Must be used in any subsequent update requests of this resource. Can be obtained when GETting the resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContentType(projectId: string, contentTypeName: string, body?: ContentType, xResourceVersion?: string, options?: any): AxiosPromise<ContentType> {
            return ContentTypeOperationsApiFp(configuration).putContentType(projectId, contentTypeName, body, xResourceVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentTypeOperationsApi - object-oriented interface
 * @export
 * @class ContentTypeOperationsApi
 * @extends {BaseAPI}
 */
export class ContentTypeOperationsApi extends BaseAPI {
    /**
     * 
     * @summary Get a content type
     * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
     * @param {string} contentTypeName Name of content type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentTypeOperationsApi
     */
    public getContentType(projectId: string, contentTypeName: string, options?: any) {
        return ContentTypeOperationsApiFp(this.configuration).getContentType(projectId, contentTypeName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all content types
     * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentTypeOperationsApi
     */
    public getContentTypes(projectId: string, options?: any) {
        return ContentTypeOperationsApiFp(this.configuration).getContentTypes(projectId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create or update a content type
     * @param {string} projectId Project specifier. Valid values: &#x27;core&#x27;, &#x27;development&#x27;
     * @param {string} contentTypeName Name of content type
     * @param {ContentType} [body] 
     * @param {string} [xResourceVersion] Resource&#x27;s version. Must be used in any subsequent update requests of this resource. Can be obtained when GETting the resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentTypeOperationsApi
     */
    public putContentType(projectId: string, contentTypeName: string, body?: ContentType, xResourceVersion?: string, options?: any) {
        return ContentTypeOperationsApiFp(this.configuration).putContentType(projectId, contentTypeName, body, xResourceVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
