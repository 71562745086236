/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, {AxiosInstance, AxiosPromise} from 'axios';
import {Configuration} from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequestArgs, RequiredError} from '../base';

/**
 * ChannelPageOperationsApi - axios parameter creator
 * @export
 */
export const GenericPluginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {

        // exec: async (method: string, path: string, channelId?: string, body?: any, xResourceVersion?: string, options: any = {}): Promise<RequestArgs> => {
        //     const localVarPath = path;
        //     // use dummy base URL string because the URL constructor only accepts absolute URLs.
        //     const localVarUrlObj = new URL(localVarPath, 'https://example.com');
        //     let baseOptions;
        //     if (configuration) {
        //         baseOptions = configuration.baseOptions;
        //     }
        //     const localVarRequestOptions = {method: method, ...baseOptions, ...options};
        //     const localVarHeaderParameter = {} as any;
        //     const localVarQueryParameter = {} as any;
        //
        //     // authentication bearerAuth required
        //     if (configuration && configuration.apiKey) {
        //         const localVarApiKeyValue = typeof configuration.apiKey === 'function'
        //             ? await configuration.apiKey("x-auth-token")
        //             : await configuration.apiKey;
        //         localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
        //     }
        //
        //     const query = new URLSearchParams(localVarUrlObj.search);
        //     for (const key in localVarQueryParameter) {
        //         query.set(key, localVarQueryParameter[key]);
        //     }
        //     for (const key in options.query) {
        //         query.set(key, options.query[key]);
        //     }
        //     localVarUrlObj.search = (new URLSearchParams(query)).toString();
        //     let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        //     localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
        //
        //     return {
        //         url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        //         options: localVarRequestOptions,
        //     };
        // },

        delete: async (path: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = path;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },

        get: async (path: string, channelId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            const localVarPath = path
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)));

            console.log('test get', localVarPath);

            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },


        put: async (path: string, channelId?: string, body?: any, xResourceVersion?: string, options: any = {}): Promise<RequestArgs> => {

            // verify required parameter 'layoutName' is not null or undefined
            // if (body === null || body === undefined) {
            //     throw new RequiredError('body', 'Required parameter layoutName was null or undefined when calling putChannelPage.');
            // }
            const localVarPath = path
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)));

            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            if (xResourceVersion !== undefined && xResourceVersion !== null) {
                localVarHeaderParameter['X-Resource-Version'] = String(xResourceVersion);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GenericPluginApi - functional programming interface
 * @export
 */
export const GenericPluginApiFp = function (configuration?: Configuration) {
    return {


        async delete(path: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GenericPluginApiAxiosParamCreator(configuration).delete(path, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },

        async get(path: string, channelId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise> {
            const localVarAxiosArgs = await GenericPluginApiAxiosParamCreator(configuration).get(path, channelId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },


        async put(path: string, channelId?: string, body?: any, xResourceVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise> {
            const localVarAxiosArgs = await GenericPluginApiAxiosParamCreator(configuration).put(path, channelId, body, xResourceVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GenericPluginApi - factory interface
 * @export
 */
export const GenericPluginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {

        delete(path: string, options?: any): AxiosPromise<void> {
            return GenericPluginApiFp(configuration).delete(path, options).then((request) => request(axios, basePath));
        },

        get(path: string, options?: any): AxiosPromise {
            return GenericPluginApiFp(configuration).get(path, options).then((request) => request(axios, basePath));
        },

        put(path: string, body?: any, xResourceVersion?: string, options?: any): AxiosPromise {
            return GenericPluginApiFp(configuration).put(path, body, xResourceVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GenericPluginApi - object-oriented interface
 * @export
 * @class GenericPluginApi
 * @extends {BaseAPI}
 */
export class GenericPluginApi extends BaseAPI {

    public delete(path: string, options?: any) {
        return GenericPluginApiFp(this.configuration).delete(path, options).then((request) => request(this.axios, this.basePath));
    }

    public get(path: string, channelId?: string, options?: any) {
        return GenericPluginApiFp(this.configuration).get(path, channelId, options).then((request) => request(this.axios, this.basePath));
    }


    public put(path: string, channelId?: string, body?: any, xResourceVersion?: string, options?: any) {
        return GenericPluginApiFp(this.configuration).put(path, channelId, body, xResourceVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
