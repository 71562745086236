import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AppBar,
    Button,
    Container,
    Toolbar,
    Typography,
    withStyles
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import {getBaseUrl, getUiExtensionsApi} from "../ApiContext";
import {LogContext} from "../LogContext";
import {UiExtension} from "../api/models/integrations/ui-extension";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


type UiState = {
    items: Array<UiExtension>

}
type UiProps = {
    classes: any
}

// @ts-ignore
const styles = theme => {
    return ({
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    });
};


class UiExtensions extends React.Component<UiProps, UiState> {

    static contextType = LogContext;
    context!: React.ContextType<typeof LogContext>;

    constructor(props: UiProps) {
        super(props);

        this.state = {
            items: []
        }
    }

    componentDidMount(): void {
        getUiExtensionsApi().getUiExtensions().then(response => this.setState({items: response?.data ?? []}))
    }


    render() {
        const {classes} = this.props;
        return <>
            <AppBar position="sticky" variant={'outlined'} color={'default'}>
                <Toolbar>
                    <Button
                        // disabled={!noDevelopmentProject}
                        variant="outlined"
                        color="primary"
                        style={{marginRight: '10px'}}
                        startIcon={<Icon className="fas fa-list"/>}
                        onClick={() => window.open(`${getBaseUrl()}/cms/integrations`, 'new')}
                    >
                        All CMS integrations
                    </Button>
                    <Button
                        // disabled={!noDevelopmentProject}
                        variant="outlined"
                        color="primary"
                        style={{marginRight: '10px'}}
                        startIcon={<Icon className="fas fa-plus"/>}
                        onClick={() => window.open(`${getBaseUrl()}/cms/integrations/ui-extensions/add`, 'new')}
                    >
                        Add new
                    </Button>
                </Toolbar>
            </AppBar>
                {this.state.items.map((extension:UiExtension, index:number) => {
                    return (
                        <Accordion key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}>
                                <Typography className={classes.heading}>{extension.displayName}</Typography>
                                <Typography className={classes.secondaryHeading}>{extension.id}</Typography>
                            </AccordionSummary>
                            <Container>
                                <pre>{JSON.stringify(extension, undefined, 2)}</pre>
                            </Container>
                        </Accordion>
                    )
                })}
        </>
    }

}

export default withStyles(styles)(UiExtensions);
