/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Channel } from '../models/site';
import { FieldGroup } from '../models/site';
import { ParameterType } from '../models/site';
/**
 * ChannelOperationsApi - axios parameter creator
 * @export
 */
export const ChannelOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete the meta-data info for a custom channel parameter, or (only) reset a builtin channel parameter
         * @param {string} channelId 
         * @param {string} parameterName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelParameterInfo: async (channelId: string, parameterName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling deleteChannelParameterInfo.');
            }
            // verify required parameter 'parameterName' is not null or undefined
            if (parameterName === null || parameterName === undefined) {
                throw new RequiredError('parameterName','Required parameter parameterName was null or undefined when calling deleteChannelParameterInfo.');
            }
            const localVarPath = `/channels/{channel_id}/parameters/{parameter_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"parameter_name"}}`, encodeURIComponent(String(parameterName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a field group
         * @param {string} channelId 
         * @param {string} fieldgroupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFieldGroup: async (channelId: string, fieldgroupName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling deleteFieldGroup.');
            }
            // verify required parameter 'fieldgroupName' is not null or undefined
            if (fieldgroupName === null || fieldgroupName === undefined) {
                throw new RequiredError('fieldgroupName','Required parameter fieldgroupName was null or undefined when calling deleteFieldGroup.');
            }
            const localVarPath = `/channels/{channel_id}/fieldgroups/{fieldgroup_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"fieldgroup_name"}}`, encodeURIComponent(String(fieldgroupName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a channel
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannel: async (channelId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannel.');
            }
            const localVarPath = `/channels/{channel_id}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the meta-data for a specific channel parameter
         * @param {string} channelId 
         * @param {string} parameterName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelParameterInfo: async (channelId: string, parameterName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannelParameterInfo.');
            }
            // verify required parameter 'parameterName' is not null or undefined
            if (parameterName === null || parameterName === undefined) {
                throw new RequiredError('parameterName','Required parameter parameterName was null or undefined when calling getChannelParameterInfo.');
            }
            const localVarPath = `/channels/{channel_id}/parameters/{parameter_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"parameter_name"}}`, encodeURIComponent(String(parameterName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the meta-data for all available channel parameters
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelParametersInfo: async (channelId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannelParametersInfo.');
            }
            const localVarPath = `/channels/{channel_id}/parameters`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all channels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannels: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the meta-data for a specific field group
         * @param {string} channelId 
         * @param {string} fieldgroupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldGroup: async (channelId: string, fieldgroupName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getFieldGroup.');
            }
            // verify required parameter 'fieldgroupName' is not null or undefined
            if (fieldgroupName === null || fieldgroupName === undefined) {
                throw new RequiredError('fieldgroupName','Required parameter fieldgroupName was null or undefined when calling getFieldGroup.');
            }
            const localVarPath = `/channels/{channel_id}/fieldgroups/{fieldgroup_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"fieldgroup_name"}}`, encodeURIComponent(String(fieldgroupName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the meta-data for all available field groups
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldGroups: async (channelId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getFieldGroups.');
            }
            const localVarPath = `/channels/{channel_id}/fieldgroups`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add or update the meta-data for a specific channel parameter
         * @param {string} channelId 
         * @param {string} parameterName 
         * @param {ParameterType} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChannelParameterInfo: async (channelId: string, parameterName: string, body?: ParameterType, xResourceVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling putChannelParameterInfo.');
            }
            // verify required parameter 'parameterName' is not null or undefined
            if (parameterName === null || parameterName === undefined) {
                throw new RequiredError('parameterName','Required parameter parameterName was null or undefined when calling putChannelParameterInfo.');
            }
            const localVarPath = `/channels/{channel_id}/parameters/{parameter_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"parameter_name"}}`, encodeURIComponent(String(parameterName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            if (xResourceVersion !== undefined && xResourceVersion !== null) {
                localVarHeaderParameter['X-Resource-Version'] = String(xResourceVersion);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add or update the meta-data for a specific field group
         * @param {string} channelId 
         * @param {string} fieldgroupName 
         * @param {FieldGroup} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFieldGroup: async (channelId: string, fieldgroupName: string, body?: FieldGroup, xResourceVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling putFieldGroup.');
            }
            // verify required parameter 'fieldgroupName' is not null or undefined
            if (fieldgroupName === null || fieldgroupName === undefined) {
                throw new RequiredError('fieldgroupName','Required parameter fieldgroupName was null or undefined when calling putFieldGroup.');
            }
            const localVarPath = `/channels/{channel_id}/fieldgroups/{fieldgroup_name}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)))
                .replace(`{${"fieldgroup_name"}}`, encodeURIComponent(String(fieldgroupName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            if (xResourceVersion !== undefined && xResourceVersion !== null) {
                localVarHeaderParameter['X-Resource-Version'] = String(xResourceVersion);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing channel (branch)
         * @param {string} channelId 
         * @param {Channel} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannel: async (channelId: string, body?: Channel, xResourceVersion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling updateChannel.');
            }
            const localVarPath = `/channels/{channel_id}`
                .replace(`{${"channel_id"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("x-auth-token")
                    : await configuration.apiKey;
                localVarHeaderParameter["x-auth-token"] = localVarApiKeyValue;
            }

            if (xResourceVersion !== undefined && xResourceVersion !== null) {
                localVarHeaderParameter['X-Resource-Version'] = String(xResourceVersion);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChannelOperationsApi - functional programming interface
 * @export
 */
export const ChannelOperationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete the meta-data info for a custom channel parameter, or (only) reset a builtin channel parameter
         * @param {string} channelId 
         * @param {string} parameterName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChannelParameterInfo(channelId: string, parameterName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ChannelOperationsApiAxiosParamCreator(configuration).deleteChannelParameterInfo(channelId, parameterName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a field group
         * @param {string} channelId 
         * @param {string} fieldgroupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFieldGroup(channelId: string, fieldgroupName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ChannelOperationsApiAxiosParamCreator(configuration).deleteFieldGroup(channelId, fieldgroupName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a channel
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannel(channelId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Channel>> {
            const localVarAxiosArgs = await ChannelOperationsApiAxiosParamCreator(configuration).getChannel(channelId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the meta-data for a specific channel parameter
         * @param {string} channelId 
         * @param {string} parameterName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelParameterInfo(channelId: string, parameterName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterType>> {
            const localVarAxiosArgs = await ChannelOperationsApiAxiosParamCreator(configuration).getChannelParameterInfo(channelId, parameterName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the meta-data for all available channel parameters
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelParametersInfo(channelId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParameterType>>> {
            const localVarAxiosArgs = await ChannelOperationsApiAxiosParamCreator(configuration).getChannelParametersInfo(channelId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all channels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannels(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Channel>>> {
            const localVarAxiosArgs = await ChannelOperationsApiAxiosParamCreator(configuration).getChannels(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the meta-data for a specific field group
         * @param {string} channelId 
         * @param {string} fieldgroupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldGroup(channelId: string, fieldgroupName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldGroup>> {
            const localVarAxiosArgs = await ChannelOperationsApiAxiosParamCreator(configuration).getFieldGroup(channelId, fieldgroupName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the meta-data for all available field groups
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldGroups(channelId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FieldGroup>>> {
            const localVarAxiosArgs = await ChannelOperationsApiAxiosParamCreator(configuration).getFieldGroups(channelId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add or update the meta-data for a specific channel parameter
         * @param {string} channelId 
         * @param {string} parameterName 
         * @param {ParameterType} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putChannelParameterInfo(channelId: string, parameterName: string, body?: ParameterType, xResourceVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParameterType>> {
            const localVarAxiosArgs = await ChannelOperationsApiAxiosParamCreator(configuration).putChannelParameterInfo(channelId, parameterName, body, xResourceVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add or update the meta-data for a specific field group
         * @param {string} channelId 
         * @param {string} fieldgroupName 
         * @param {FieldGroup} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFieldGroup(channelId: string, fieldgroupName: string, body?: FieldGroup, xResourceVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FieldGroup>> {
            const localVarAxiosArgs = await ChannelOperationsApiAxiosParamCreator(configuration).putFieldGroup(channelId, fieldgroupName, body, xResourceVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update an existing channel (branch)
         * @param {string} channelId 
         * @param {Channel} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChannel(channelId: string, body?: Channel, xResourceVersion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Channel>> {
            const localVarAxiosArgs = await ChannelOperationsApiAxiosParamCreator(configuration).updateChannel(channelId, body, xResourceVersion, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ChannelOperationsApi - factory interface
 * @export
 */
export const ChannelOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete the meta-data info for a custom channel parameter, or (only) reset a builtin channel parameter
         * @param {string} channelId 
         * @param {string} parameterName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChannelParameterInfo(channelId: string, parameterName: string, options?: any): AxiosPromise<void> {
            return ChannelOperationsApiFp(configuration).deleteChannelParameterInfo(channelId, parameterName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a field group
         * @param {string} channelId 
         * @param {string} fieldgroupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFieldGroup(channelId: string, fieldgroupName: string, options?: any): AxiosPromise<void> {
            return ChannelOperationsApiFp(configuration).deleteFieldGroup(channelId, fieldgroupName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a channel
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannel(channelId: string, options?: any): AxiosPromise<Channel> {
            return ChannelOperationsApiFp(configuration).getChannel(channelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the meta-data for a specific channel parameter
         * @param {string} channelId 
         * @param {string} parameterName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelParameterInfo(channelId: string, parameterName: string, options?: any): AxiosPromise<ParameterType> {
            return ChannelOperationsApiFp(configuration).getChannelParameterInfo(channelId, parameterName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the meta-data for all available channel parameters
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelParametersInfo(channelId: string, options?: any): AxiosPromise<Array<ParameterType>> {
            return ChannelOperationsApiFp(configuration).getChannelParametersInfo(channelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all channels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannels(options?: any): AxiosPromise<Array<Channel>> {
            return ChannelOperationsApiFp(configuration).getChannels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the meta-data for a specific field group
         * @param {string} channelId 
         * @param {string} fieldgroupName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldGroup(channelId: string, fieldgroupName: string, options?: any): AxiosPromise<FieldGroup> {
            return ChannelOperationsApiFp(configuration).getFieldGroup(channelId, fieldgroupName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the meta-data for all available field groups
         * @param {string} channelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldGroups(channelId: string, options?: any): AxiosPromise<Array<FieldGroup>> {
            return ChannelOperationsApiFp(configuration).getFieldGroups(channelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add or update the meta-data for a specific channel parameter
         * @param {string} channelId 
         * @param {string} parameterName 
         * @param {ParameterType} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChannelParameterInfo(channelId: string, parameterName: string, body?: ParameterType, xResourceVersion?: string, options?: any): AxiosPromise<ParameterType> {
            return ChannelOperationsApiFp(configuration).putChannelParameterInfo(channelId, parameterName, body, xResourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add or update the meta-data for a specific field group
         * @param {string} channelId 
         * @param {string} fieldgroupName 
         * @param {FieldGroup} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFieldGroup(channelId: string, fieldgroupName: string, body?: FieldGroup, xResourceVersion?: string, options?: any): AxiosPromise<FieldGroup> {
            return ChannelOperationsApiFp(configuration).putFieldGroup(channelId, fieldgroupName, body, xResourceVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing channel (branch)
         * @param {string} channelId 
         * @param {Channel} [body] 
         * @param {string} [xResourceVersion] Resource&#x27;s version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChannel(channelId: string, body?: Channel, xResourceVersion?: string, options?: any): AxiosPromise<Channel> {
            return ChannelOperationsApiFp(configuration).updateChannel(channelId, body, xResourceVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChannelOperationsApi - object-oriented interface
 * @export
 * @class ChannelOperationsApi
 * @extends {BaseAPI}
 */
export class ChannelOperationsApi extends BaseAPI {
    /**
     * 
     * @summary Delete the meta-data info for a custom channel parameter, or (only) reset a builtin channel parameter
     * @param {string} channelId 
     * @param {string} parameterName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelOperationsApi
     */
    public deleteChannelParameterInfo(channelId: string, parameterName: string, options?: any) {
        return ChannelOperationsApiFp(this.configuration).deleteChannelParameterInfo(channelId, parameterName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a field group
     * @param {string} channelId 
     * @param {string} fieldgroupName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelOperationsApi
     */
    public deleteFieldGroup(channelId: string, fieldgroupName: string, options?: any) {
        return ChannelOperationsApiFp(this.configuration).deleteFieldGroup(channelId, fieldgroupName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a channel
     * @param {string} channelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelOperationsApi
     */
    public getChannel(channelId: string, options?: any) {
        return ChannelOperationsApiFp(this.configuration).getChannel(channelId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the meta-data for a specific channel parameter
     * @param {string} channelId 
     * @param {string} parameterName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelOperationsApi
     */
    public getChannelParameterInfo(channelId: string, parameterName: string, options?: any) {
        return ChannelOperationsApiFp(this.configuration).getChannelParameterInfo(channelId, parameterName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the meta-data for all available channel parameters
     * @param {string} channelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelOperationsApi
     */
    public getChannelParametersInfo(channelId: string, options?: any) {
        return ChannelOperationsApiFp(this.configuration).getChannelParametersInfo(channelId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all channels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelOperationsApi
     */
    public getChannels(options?: any) {
        return ChannelOperationsApiFp(this.configuration).getChannels(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the meta-data for a specific field group
     * @param {string} channelId 
     * @param {string} fieldgroupName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelOperationsApi
     */
    public getFieldGroup(channelId: string, fieldgroupName: string, options?: any) {
        return ChannelOperationsApiFp(this.configuration).getFieldGroup(channelId, fieldgroupName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the meta-data for all available field groups
     * @param {string} channelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelOperationsApi
     */
    public getFieldGroups(channelId: string, options?: any) {
        return ChannelOperationsApiFp(this.configuration).getFieldGroups(channelId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add or update the meta-data for a specific channel parameter
     * @param {string} channelId 
     * @param {string} parameterName 
     * @param {ParameterType} [body] 
     * @param {string} [xResourceVersion] Resource&#x27;s version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelOperationsApi
     */
    public putChannelParameterInfo(channelId: string, parameterName: string, body?: ParameterType, xResourceVersion?: string, options?: any) {
        return ChannelOperationsApiFp(this.configuration).putChannelParameterInfo(channelId, parameterName, body, xResourceVersion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add or update the meta-data for a specific field group
     * @param {string} channelId 
     * @param {string} fieldgroupName 
     * @param {FieldGroup} [body] 
     * @param {string} [xResourceVersion] Resource&#x27;s version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelOperationsApi
     */
    public putFieldGroup(channelId: string, fieldgroupName: string, body?: FieldGroup, xResourceVersion?: string, options?: any) {
        return ChannelOperationsApiFp(this.configuration).putFieldGroup(channelId, fieldgroupName, body, xResourceVersion, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update an existing channel (branch)
     * @param {string} channelId 
     * @param {Channel} [body] 
     * @param {string} [xResourceVersion] Resource&#x27;s version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelOperationsApi
     */
    public updateChannel(channelId: string, body?: Channel, xResourceVersion?: string, options?: any) {
        return ChannelOperationsApiFp(this.configuration).updateChannel(channelId, body, xResourceVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
