import React from 'react';
import {FormControl, MenuItem, Select} from "@material-ui/core";
import {getChannelOperationsApi} from "../ApiContext";
import {ChannelOperationsApi} from "../api/apis/channel-operations-api";
import {Channel} from "../api/models/site";
import {Nullable} from "../api/models/site/nullable";
import {Cookies} from "react-cookie";


type ChannelSwitcherState = {
    channels: Array<Channel>
    currentChannelId: Nullable<string>,
}
type ChannelSwitcherProps = {
    onChannelChanged: (channelId: string) => void
}

const cookies = new Cookies();

class ChannelSwitcher extends React.Component<ChannelSwitcherProps, ChannelSwitcherState> {

    constructor(props: ChannelSwitcherProps) {
        super(props);

        this.state = {
            channels: [],
            currentChannelId: null,
        };
    }

    componentDidMount(): void {
        this.updateChannels();
    }

    updateChannels() {
        const api: ChannelOperationsApi = getChannelOperationsApi();
        api.getChannels().then(value => {
            const channels: Array<Channel> = value.data;
            const prefChannelId = cookies.get("preferredChannelId");
            const currentChannel = channels.some(e => e.id === prefChannelId) ? prefChannelId : channels.find(channel => channel.branch !== null)?.id;
            this.setState({
                channels: channels,
                currentChannelId: currentChannel
            }, () => currentChannel && this.props.onChannelChanged(currentChannel))
        });
    }

    render() {
        return (
            <FormControl style={{marginRight: '10px'}}>
                {this.state.currentChannelId &&
                <Select value={this.state.currentChannelId}
                        onChange={(event) => this.setState({currentChannelId: event.target.value as string},
                            () => {
                                const channelId = event.target.value as string;
                                cookies.set('preferredChannelId', channelId, {secure: true, sameSite: 'strict'});
                                this.props.onChannelChanged(channelId)
                            })}>
                    {this.state.channels.map(channel => {
                        return <MenuItem
                            disabled={channel.branch === null}
                            key={channel.id}
                            value={channel.id}
                        >
                            {channel.id}
                        </MenuItem>
                    })}
                </Select>
                }
            </FormControl>)
    }

}

export default ChannelSwitcher;
