import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    MenuItem,
    Select
} from "@material-ui/core";
import {getChannelOperationsApi} from "../ApiContext";
import {ChannelOperationsApi} from "../api/apis/channel-operations-api";
import {Channel} from "../api/models/site";
import {Nullable} from "../api/models/site/nullable";
import CompareArrowsOutlinedIcon from '@material-ui/icons/CompareArrowsOutlined';
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import SyncOutlinedIcon from '@material-ui/icons/SyncOutlined';
import {IChange} from "json-diff-ts/lib/jsonDiff";
import {InstallationAction} from "../plugins/Plugins";
import {exportActions, importActions} from "./common-utils";

type CompareToState = {
    compareToChannels: Array<Channel>
    currentCompareToChannelId: Nullable<string>,
    openDiff: boolean;
    diff: IChange[]
}
type CompareToProps = {
    currentChannelId: string,
    runCompare: (currentChannelId: string, compareToChannelId: string) => Promise<IChange[]>
    generatePatch: (changes: IChange[], currentCompareToChannelId: string) => Array<InstallationAction>
}

class CompareTo extends React.Component<CompareToProps, CompareToState> {

    constructor(props: CompareToProps) {
        super(props);

        this.state = {
            openDiff: false,
            diff: [],
            compareToChannels: [],
            currentCompareToChannelId: null,
        };
    }

    componentDidMount(): void {
        this.updateChannels();
    }

    updateChannels() {
        const api: ChannelOperationsApi = getChannelOperationsApi();
        api.getChannels().then(value => {
            const channels: Array<Channel> = value.data;
            const projectIdPostFix = this.props.currentChannelId?.substring(this.props.currentChannelId?.lastIndexOf('-'))
            const currentCompareToChannels = channels.filter(channel => channel.id.endsWith(projectIdPostFix)).filter(channel => channel.id !== this.props.currentChannelId);
            if (currentCompareToChannels.length > 0) {
                this.setState({
                    compareToChannels: currentCompareToChannels,
                    currentCompareToChannelId: currentCompareToChannels[0].id
                })
            }
        });
    }

    render() {
        const {openDiff, diff, currentCompareToChannelId} = this.state
        const diffString = JSON.stringify(diff, null, 2);
        return (
            <>
                {currentCompareToChannelId &&
                <Button
                    variant="outlined"
                    color="primary"
                    style={{marginRight: '10px'}}
                    startIcon={<CompareArrowsOutlinedIcon/>}
                    onClick={() => {
                        const test = this.props.runCompare(this.props.currentChannelId, this.state.currentCompareToChannelId as string);
                        test.then(value => this.setState({openDiff: true, diff: value}))
                    }}>
                    <FormControl style={{marginRight: '10px', textTransform: "initial"}}>
                        {this.state.currentCompareToChannelId &&
                        <Select value={this.state.currentCompareToChannelId}
                                onChange={(event) => this.setState({currentCompareToChannelId: event.target.value as string})}>
                            {this.state.compareToChannels.map(channel => {
                                return <MenuItem
                                    disabled={channel.branch === null}
                                    key={channel.id}
                                    value={channel.id}
                                >
                                    {channel.id}
                                </MenuItem>
                            })}
                        </Select>
                        }
                    </FormControl>
                </Button>
                }
                <Dialog
                    open={openDiff}
                    onClose={() => {
                        this.setState({openDiff: false, diff: []})
                    }}
                    maxWidth={"lg"}
                >
                    <DialogTitle>compare: {this.props.currentChannelId} with {this.state.currentCompareToChannelId}</DialogTitle>
                    <DialogContent>
                        {diff.length > 0 ? <pre>{diffString}</pre> : <p>no differences</p>}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={diff.length === 0}
                            variant="outlined"
                            color="primary"
                            style={{marginRight: '10px'}}
                            startIcon={<FileCopyOutlinedIcon/>}
                            onClick={() => {
                                exportActions(this.props.generatePatch(this.state.diff, this.state.currentCompareToChannelId as string))
                            }}
                        >
                            create patch
                        </Button>
                        <Button
                            disabled={diff.length === 0}
                            variant="outlined"
                            color="primary"
                            style={{marginRight: '10px'}}
                            startIcon={<SyncOutlinedIcon/>}
                            onClick={() => {
                                importActions(
                                    this.props.generatePatch(this.state.diff, this.state.currentCompareToChannelId as string),
                                    undefined,
                                    () => {
                                        const test = this.props.runCompare(this.props.currentChannelId, this.state.currentCompareToChannelId as string);
                                        test.then(value => this.setState({openDiff: true, diff: value}))
                                    },
                                    undefined)

                            }}
                        >
                            sync {this.props.currentChannelId} -&gt; {this.state.currentCompareToChannelId}
                        </Button>

                    </DialogActions>
                </Dialog>
            </>)
    }

}

export default CompareTo;
