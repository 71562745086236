/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface ParameterType
 */
export interface ParameterType {
    /**
     * 
     * @type {string}
     * @memberof ParameterType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ParameterType
     */
    valueType: ParameterTypeValueTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterType
     */
    required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterType
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ParameterType
     */
    overlay?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ParameterType
     */
    defaultValue?: any;
    /**
     * 
     * @type {string}
     * @memberof ParameterType
     */
    displayName?: string;
    /**
     * System readonly parameter
     * @type {boolean}
     * @memberof ParameterType
     */
    system?: boolean;
    /**
     * todo
     * @type {ParameterConfigType}
     * @memberof ParameterType
     */
    config?: any;
}

/**
    * @export
    * @enum {string}
    */
export enum ParameterTypeValueTypeEnum {
    String = 'string',
    Calendar = 'calendar',
    Boolean = 'boolean',
    Integer = 'integer',
    Number = 'number'
}

